import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { YearlyWrapper } from "./styles";
import DropDownComponent from "./yearlyDropDown";
import {
  generateDateOptions,
  generateAllYears,
} from "../../../util/generateYear";
import { changeSelectedYear, selectedYear } from "redux/app/reducers/tabsSlice";

const defaultBaseId = "appkscyvJutKmwgNB";

export const YearlyDropdown = () => {
  const [options, setOptions] = useState([]);
  const [baseId, setBaseId] = useState(
    localStorage.getItem("baseId") || defaultBaseId
  );
  const userType = localStorage.getItem("userType");
  const dispatch = useDispatch();
  const yearSelected = useSelector(selectedYear);

  useEffect(() => {
    const updateOptions = () => {
      const latestBaseId = localStorage.getItem("baseId") || defaultBaseId;
      if (latestBaseId !== baseId) {
        setBaseId(latestBaseId);
      }

      if (userType === "ADMIN") {
        const allYearsArray = generateAllYears().map((year) => ({
          label: year,
          value: year,
        }));
        setOptions(allYearsArray);
      } else {
        const storedYears = localStorage.getItem("years");
        if (storedYears && JSON.parse(storedYears).length > 0) {
          const yearsArray = JSON.parse(storedYears).map((year) => ({
            label: year,
            value: year,
          }));
          setOptions(yearsArray);
        } else {
          const generatedOptions = generateDateOptions(latestBaseId, true);
          if (generatedOptions.length === 0) {
            const currentYear = new Date().getFullYear();
            const startYear = 2023;
            const yearOptions = [];
            for (let year = startYear; year <= currentYear; year++) {
              yearOptions.push({
                label: year.toString(),
                value: year.toString(),
              });
            }
            setOptions(yearOptions);
          } else {
            // Ensure unique options
            const uniqueOptions = Array.from(
              new Set(generatedOptions.map((opt) => opt.value))
            ).map((value) => ({ label: value, value }));
            setOptions(uniqueOptions);
          }
        }
      }
    };

    updateOptions();

    const intervalId = setInterval(updateOptions, 500);

    return () => clearInterval(intervalId);
  }, [baseId, userType]);

  useEffect(() => {
    if (options.length > 0 && !yearSelected) {
      const mostRecentOption = Math.max(
        ...options.map((opt) => parseInt(opt.value, 10))
      ).toString();
      dispatch(changeSelectedYear(mostRecentOption));
    }
  }, [options, dispatch, yearSelected]);

  return (
    <YearlyWrapper>
      <DropDownComponent
        options={options}
        defaultValue={yearSelected}
        getPopupContainer={(trigger) => trigger.parentElement}
      />
    </YearlyWrapper>
  );
};
