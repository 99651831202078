import Airtable from "airtable";
import fetchingMonthlyData, {
  fetchDataBilling,
  fetchDataMonth,
} from "./monthly";
import formattedAmount from "util/formattedString";
import { requestCompleted, requestStart } from ".";

const apiKey =
  "patfIHv7xmjArvAKG.8821159aa381f9044feeb21a631149370f60be5e8cbfe11ffde3ccec187f33ca";

const fetchBase = () => {
  const baseId = localStorage.getItem("baseId");
  console.log("Fetching Base: API Key:", apiKey, "Base ID:", baseId);

  if (apiKey && baseId) {
    const airtableConfig = {
      endpointUrl: "https://api.airtable.com",
      apiKey: apiKey,
    };

    Airtable.configure(airtableConfig);
    console.log("Airtable Configured with", airtableConfig);
    return Airtable.base(baseId);
  } else {
    console.error("API Key or Base ID is missing");
    return null;
  }
};

async function fetchBookingData(base) {
  base = base || fetchBase();
  if (!base) {
    console.error("Base is not initialized");
    return [];
  }

  console.log("Fetching Booking Data");

  return new Promise((resolve, reject) => {
    const recordsArray = [];
    base("Yearly Revenue")
      .select({})
      .eachPage(
        function page(records, fetchNextPage) {
          console.log("Fetched Records Page:", records);
          records.forEach((record) => {
            console.log("Processing Record:", record);
            recordsArray.push(record.fields);
          });
          fetchNextPage();
        },
        function done(err) {
          if (err) {
            console.error("Error fetching booking data:", err);
            reject(err);
            return;
          }
          console.log("Fetched All Records:", recordsArray);
          resolve(recordsArray);
        }
      );
  }).catch((error) => {
    console.error("Error fetching booking data:", error);
    return [];

  });
}

async function fetchRecordsSummary(year, base) {
  try {
    console.log("Fetching Records Summary for Year:", year);
    if (!year) {
      throw new Error("Year is not selected");
    }
    const storeBase = fetchBase();
    base = base || storeBase;

    if (!base) {
      console.error("Base is not initialized");
      return null;
    }

    const bookingData = await fetchBookingData(base);
    console.log("Booking Data:", bookingData);

    const filteredData = bookingData.filter(
      (each) => each.yearID === year
    )?.[0];
    console.log("Filtered Data for Year:", filteredData);

    return filteredData;
  } catch (error) {
    console.error("Error fetching booking data:", error);
  }
}

const getMonthlyData = async (year, base, dispatch) => {
  try {
    console.log("Fetching Monthly Data for Year:", year);
    if (!year) {
      throw new Error("Year is not selected");
    }
    const storeBase = fetchBase();
    base = base || storeBase;

    if (!base) {
      console.error("Base is not initialized");
      return { barChartData: [], tableData: [], rawData: [] };
    }

    console.log("Fetching Monthly Data...");
    const data = await fetchingMonthlyData(base, year, dispatch);
    console.log("Fetched Monthly Data:", data);

    const monthOrder = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const abbrevMonthOrder = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Map and fill data for both bar chart and table
    const filledData = monthOrder.map((monthName, index) => {
      const monthData = data.find(
        (eachMonth) => eachMonth["Mth"] === abbrevMonthOrder[index]
      );
      const capitalizedMonthName = monthName.toUpperCase(); // Capitalize the full month name

      return monthData
        ? {
            ...monthData,
            Mth: capitalizedMonthName,
            abbrevMth: abbrevMonthOrder[index], // Store the abbreviation for the bar chart
            monthID: `${capitalizedMonthName} ${year}`,
          }
        : {
            Mth: capitalizedMonthName,
            abbrevMth: abbrevMonthOrder[index], // Store the abbreviation for the bar chart
            "Owner Revenue": 0,
            monthID: `${capitalizedMonthName} ${year}`,
          };
    });

    // Prepare bar chart data using abbreviations
    const barChartData = filledData.map((eachMonth) => ({
      key: eachMonth["monthID"],
      label: eachMonth["abbrevMth"], // Use the abbreviation for the bar chart
      value: eachMonth["Owner Revenue"] ?? 0,
    }));

    console.log("Bar Chart Data:", barChartData);

    // Prepare table data using capitalized month names
    const tableData = filledData.map((eachMonth) => ({
      key: eachMonth["monthID"],
      avg_rate_booking: formattedAmount(eachMonth["Booking Rate"], true) ?? "-",
      monthly_revenue: formattedAmount(eachMonth["Gross Revenue"], true) ?? "-",
      monthly_year: eachMonth["Mth"], // Capitalized month name for the table
      avg_nighty_rate: formattedAmount(eachMonth["Nightly Rate"], true) ?? "-",
      booking: eachMonth["Bookings"] || "-",
      nights: eachMonth["Nights"] || "-",
      owner_bookings: eachMonth["OwnerBookings"] ?? "no",
    }));

    console.log("Table Data:", tableData);

    return { barChartData, tableData, rawData: data };
  } catch (error) {
    console.error("Error fetching monthly data:", error);
    return { barChartData: [], tableData: [], rawData: [] };
  }
};

const fetchSpecificMonthData = async (month, base) => {
  try {
    console.log("Fetching Specific Month Data for Month:", month);
    if (!month) {
      throw new Error("Month is not selected");
    }
    const storeBase = fetchBase();
    base = base || storeBase;

    if (!base) {
      console.error("Base is not initialized");
      return null;
    }

    const monthlyData = await fetchDataMonth(base, month);
    console.log("Fetched Specific Month Data:", monthlyData);
    return monthlyData[0];
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

const fetchDataBillingMonth = async (month, base, dispatch) => {
  try {
    console.log("Fetching Billing Data for Month:", month);
    if (!month) {
      throw new Error("Month is not selected");
    }
    dispatch(requestStart());
    const storeBase = fetchBase();
    base = base || storeBase;

    if (!base) {
      console.error("Base is not initialized");
      return null;
    }

    const billingData = await fetchDataBilling(base, month);
    console.log("Fetched Billing Data:", billingData);
    dispatch(requestCompleted());
    return billingData;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export {
  getMonthlyData,
  fetchSpecificMonthData,
  fetchDataBillingMonth,
  fetchBookingData,
};

export default fetchRecordsSummary;
