import styled from "styled-components";

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 46px 46px 30px 20px;

  .main-header-heading-overview {
    font-weight: 500;
    flex: 1;
    font-size: 32px;
    font-family: Montserrat !important;
  }

  .header-custom-select .ant-select-selector {
    width: 248px !important;
    font-family: "Montserrat", sans-serif !important;
    height: 40px !important;
    lineheight: 24px;
    border-radius: 8px !important;
    background-color: #fafafa !important;
    padding-top: 8px !important;
    color: #110f0e;
    font-size: 13px;
  }

  .header-custom-select .ant-select-arrow {
    color: #110f0e;
  }
`;

export const HeaderInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 46px 0px 20px;
  margin: 20px 0;

  .header-month {
    font-weight: 600;
    font-family: "Montserrat", sans-serif !important;

    font-size: 24px;
  }
  .main-header-heading-overview {
    font-weight: 600 !important;

    flex: 1;
    font-size: 40px;
  }

  .header-custom-select .ant-select-selector {
    width: 248px !important;
    font-family: "Montserrat", sans-serif !important;

    height: 40px !important;
    lineheight: 24px;
    border-radius: 8px !important;
    background-color: #fafafa !important;
    padding-top: 8px !important;
    color: #110f0e;
    font-size: 13px;
  }

  .header-custom-select .ant-select-arrow {
    color: #110f0e;
  }
  .navigation-button {
    display: flex;
    gap: 9px;
    button {
      background-color: transparent;
      width: 133px;
      border: 1px solid #cccccccc;
      color: #110f0e;
      font-size: 11px;
      font-family: "Montserrat", sans-serif !important;

      font-weight: 500;
      border-radius: 4px;
      gap: 7px;
      align-items: center;
      display: flex;
      justify-content: center;
      &:hover {
        background-color: #f3f3f3;
      }
    }
  }
`;

export const DropDownWrapper = styled.div`
  margin-bottom: 10px;
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 248px !important;
    font-family: "Montserrat";
    font-size: 13px;
    font-weight: 400;
    line-height: 13px;
  }
`;
export const InputWrapper = styled.div`
  margin-bottom: 10px;
  .header-custom-input {
    width: 90%;
    height: 45px;
    background: #fff;
    color: #110f0e;
    border-radius: 8px;
    font-size: 13px;
  }
`;

export const DropDownDrawerWrapper = styled.div`
  margin-bottom: 20px;
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 300px !important;
    font-family: "Montserrat";
    font-size: 13px;
    font-weight: 400;
    line-height: 13px;
  }
`;
export const MonthlyOverviewImage = styled.img`
  width: 260px; /* Adjust size as needed */
  height: 260px; /* Adjust size as needed */
  margin-right: 10px; /* Add space between image and text */
`;
