import React, { useEffect } from "react";
import { Button } from "antd";
import "./styles.js";
import { HeaderInfo, HeaderWrapper } from "./styles.js";
import { useSelector, useDispatch } from "react-redux";
import {
  changeSelectedMonth,
  selectedMonth,
} from "redux/app/reducers/tabsSlice.js";
import UserInfo from "Pages/Dashboard/Content/Header/UserInfo/index.jsx";
import PreviousIcon from "Assets/Icons/PreviousIcon.jsx";
import NextIcon from "Assets/Icons/NextIcon.jsx";
import { generateDateOptions } from "util/generateYear.js";
import monthly from "Assets/Icons/monthly_cal.svg";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const Header = (props) => {
  const monthSelected = useSelector(selectedMonth);
  const userType = localStorage.getItem("userType");
  const baseID = localStorage.getItem("baseId");
  const dispatch = useDispatch();

  useEffect(() => {
    if (!monthSelected) {
      const options = generateDateOptions(baseID);
      // Sort options by value in descending order
      options.sort((a, b) => (a.value < b.value ? 1 : -1));
      if (options.length > 0) {
        dispatch(changeSelectedMonth(options[0].value));
      }
    }
  }, [monthSelected, dispatch]);

  const formatMonthSelected = (month) => {
    if (!month) {
      return "No month selected"; // Handle null or undefined month
    }
    if (month.includes("-")) {
      const [year, monthIndex] = month.split("-");
      const monthName = monthNames[parseInt(monthIndex, 10) - 1];
      return `${monthName} ${year}`;
    }
    return month; // If already in "Month Year" format
  };

  const convertMonthToValue = (month) => {
    if (!month) return "";
    if (month.includes(" ")) {
      const [monthName, year] = month.split(" ");
      const monthIndex = monthNames.indexOf(monthName) + 1;
      return `${year}-${String(monthIndex).padStart(2, "0")}`;
    }
    return month;
  };

  const convertValueToMonth = (value) => {
    if (!value) return "";
    const [year, monthIndex] = value.split("-");
    const monthName = monthNames[parseInt(monthIndex, 10) - 1];
    return `${monthName} ${year}`;
  };

  const handleNextMonth = () => {
    console.log("handleNextMonth is clicked");
    const baseId = localStorage.getItem("baseId");
    let options = generateDateOptions(baseId);
    // Sort options by value in descending order
    options.sort((a, b) => (a.value < b.value ? 1 : -1));
    console.log("generateDateOptions in next button", options);
    const monthValue = convertMonthToValue(monthSelected);
    console.log("Month Value after convert:", monthValue);
    const currentIndex = options.findIndex(
      (option) => option.value === monthValue
    );
    console.log(
      "Next - Current Index:",
      currentIndex,
      "Month Selected:",
      monthSelected,
      "Options:",
      options
    );
    if (currentIndex !== -1 && currentIndex < options.length - 1) {
      const nextMonthValue = options[currentIndex + 1].value;
      const nextMonth = convertValueToMonth(nextMonthValue);
      dispatch(changeSelectedMonth(nextMonth));
      console.log("Month Value to send:", nextMonth);
    }
  };

  const handlePreviousMonth = () => {
    console.log("handlePreviousMonth is clicked");

    const baseId = localStorage.getItem("baseId");
    let options = generateDateOptions(baseId);
    // Sort options by value in descending order
    options.sort((a, b) => (a.value < b.value ? 1 : -1));
    const monthValue = convertMonthToValue(monthSelected);
    const currentIndex = options.findIndex(
      (option) => option.value === monthValue
    );
    console.log(
      "Previous - Current Index:",
      currentIndex,
      "Month Selected:",
      monthSelected,
      "Options:",
      options
    );
    if (currentIndex > 0) {
      const previousMonthValue = options[currentIndex - 1].value;
      const previousMonth = convertValueToMonth(previousMonthValue);
      dispatch(changeSelectedMonth(previousMonth));
      console.log("Month Value to send:", previousMonth);
    }
  };

  const formattedMonthSelected = formatMonthSelected(monthSelected);

  return (
    <>
      <HeaderWrapper>
        <div className="card-wrapper">
          <div className="main-header-content">
            <img
              src={monthly}
              alt="Monthly Statement"
              className="monthly-overview-image"
              style={{ width: "30px", height: "30px" }}
            />
            <div className="main-header main-header-heading-overview">
              Monthly Overview
            </div>
          </div>
          <UserInfo />
        </div>
      </HeaderWrapper>

      <HeaderInfo>
        <div className="header-month">
          &nbsp;
          {formattedMonthSelected}
        </div>
        <div className="navigation-button">
          <Button onClick={handleNextMonth} disabled={!monthSelected}>
            <PreviousIcon /> Previous Month
          </Button>
          <Button onClick={handlePreviousMonth} disabled={!monthSelected}>
            Next Month <NextIcon />
          </Button>
        </div>
      </HeaderInfo>
    </>
  );
};

export default Header;
