import styled from "styled-components";

export const SummaryCardWraper = styled.div`
  padding: 30px 8px;
  background: #fafafa;
  border-width: 4px;
  border-radius: 8px;
  border: 1px solid transparent;
  align-items: center;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 200px;
  min-width: 0;
  transition: all 0.3s ease-in-out;

  .summary-card-title {
    color: ${(props) => props.theme.colors.black};
    font-family: "Montserrat";
    font-size: 11px;
    line-height: 11px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 12px;
    text-transform: uppercase;
    display: flex;
    align-items: center; /* Align icon and title vertically */
    justify-content: center; /* Center the title and icon horizontally */
  }

  .summary-card-value {
    color: ${(props) => props.theme.colors.black};
    font-family: "Montserrat";
    font-size: 24px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
  }

  .ant-tooltip .ant-tooltip-inner {
    color: rgb(0, 0, 0) !important;
    background-color: rgb(255, 255, 255) !important;
    font-size: 13px;
    font-weight: 500;
    font-style: italic;
    font-family: "Montserrat";
    text-align: left !important;
    padding: 10px 10px 20px 10px !important;
    border-radius: 8px !important;
    white-space: normal !important;
  }

  .summary-card-title .ant-badge {
    margin-left: 5px; /* Add space between title and tooltip icon */
  }
`;
