import { createSlice, createAction } from "@reduxjs/toolkit";

const initialState = {
  currentUnit: "",
  tableList: [],
  selectedYear: null,
  selectedMonth: null,
  loading: false, // Add loading state
};

const tabsSlice = createSlice({
  name: "tabs",
  initialState,
  reducers: {
    setUnitsList(state, { payload }) {
      state.tableList = payload;
    },
    setSelectedUnit(state, { payload }) {
      state.currentUnit = payload;
    },
    changeSelectedYear(state, { payload }) {
      state.selectedYear = payload;
    },
    changeSelectedMonth(state, { payload }) {
      state.selectedMonth = payload;
    },
    setLoading(state, { payload }) {
      // Add setLoading reducer
      state.loading = payload;
    },
  },
});

export const unitsList = (state) => state.tabs.tableList;
export const selectedUnit = (state) => state.tabs.currentUnit;
export const selectedYear = (state) => state.tabs.selectedYear;
export const selectedMonth = (state) => state.tabs.selectedMonth;
export const loading = (state) => state.tabs.loading;

export const {
  setUnitsList,
  setSelectedUnit,
  changeSelectedYear,
  changeSelectedMonth,
  setLoading,
} = tabsSlice.actions;

export default tabsSlice.reducer;
