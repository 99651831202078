import React from "react";
import { Table, Button, Space, Popconfirm } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import "./styles.css";

const UserTable = ({ users = [], onEdit, onDelete, onFilter }) => {
  const pageSize = 9;
  const loggedInUserEmail = localStorage.getItem("user_email");

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => text || record.email,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "userType",
      render: (text, record) => {
        const formatText = (str) =>
          str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
        return formatText(text || record.airTableType);
      },
    },
    {
      title: "Unit Access",
      dataIndex: ["apiData", "baseId"],
      key: "unitNo",
      render: (baseId, record) => {
        // If the role is Admin, show "All Units"
        if (record.role && record.role.toLowerCase() === "admin") {
          return "All Units";
        }
        // Otherwise, map the baseId to the corresponding unit
        if (baseId === "appkscyvJutKmwgNB") return "611/613";
        if (baseId === "appn8T3UykPjpTnV2") return "723/725";
        return baseId;
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => {
        const isAdmin = record.role.toLowerCase() === "admin";
        const isCurrentUser = record.email === loggedInUserEmail;

        return (
          <Space size="middle">
            <Button
              type="link"
              className="edit-icon"
              icon={<EditOutlined />}
              onClick={() => onEdit(record)}
            />
            {!isAdmin && (
              <Button
                type="link"
                className="filter-icon"
                icon={<FilterOutlined />}
                onClick={() => onFilter(record)}
              />
            )}
            {!isCurrentUser && (
              <Popconfirm
                title="Are you sure to delete this user?"
                onConfirm={() => onDelete(record._id)}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  type="link"
                  icon={<DeleteOutlined />}
                  style={{
                    color: "black",
                    backgroundColor: "#f8f8f8",
                    borderRadius: "6px",
                    padding: "8px",
                    transition: "background-color 0.3s, box-shadow 0.3s",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.backgroundColor = "#ff4d4f";
                    e.currentTarget.style.color = "white";
                    e.currentTarget.style.boxShadow =
                      "0 4px 8px rgba(0, 0, 0, 0.2)";
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.backgroundColor = "#f8f8f8";
                    e.currentTarget.style.color = "black";
                    e.currentTarget.style.boxShadow =
                      "0 2px 4px rgba(0, 0, 0, 0.1)";
                  }}
                />
              </Popconfirm>
            )}
          </Space>
        );
      },
    },
  ];

  return (
    <Table
      dataSource={Array.isArray(users) ? users : []}
      columns={columns}
      rowKey="_id"
      className="user-table"
      pagination={users.length > pageSize ? { pageSize } : false}
      style={{ fontFamily: "Montserrat" }}
    />
  );
};

export default UserTable;
