const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function generateOptionsFromLocalStorage() {
  const storedMonths = JSON.parse(localStorage.getItem("months") || "[]");
  const storedYears = JSON.parse(localStorage.getItem("years") || "[]");

  if (!storedMonths.length || !storedYears.length) {
    return [];
  }

  const options = [];

  storedMonths.forEach((monthYear) => {
    const [monthName, year] = monthYear.split(" ");
    options.push({
      label: `${monthName} ${year}`,
      value: `${year}-${String(monthNames.indexOf(monthName) + 1).padStart(
        2,
        "0"
      )}`,
    });
  });

  storedYears.forEach((year) => {
    const yearInt = parseInt(year, 10);
    const monthsForYear = storedMonths.filter((monthYear) => {
      return monthYear.endsWith(` ${year}`);
    });

    if (monthsForYear.length === 0) {
      const endMonth =
        yearInt === new Date().getFullYear() ? new Date().getMonth() - 1 : 11;
      for (let monthIndex = 0; monthIndex <= endMonth; monthIndex++) {
        options.push({
          label: `${monthNames[monthIndex]} ${yearInt}`,
          value: `${yearInt}-${String(monthIndex + 1).padStart(2, "0")}`,
        });
      }
    }
  });

  return options;
}

function generateAllMonthYearArray() {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  const monthYearArray = [];

  for (let year = currentYear; year >= 2018; year--) {
    const endMonth = year === currentYear ? currentMonth - 1 : 11;

    for (let month = endMonth; month >= 0; month--) {
      monthYearArray.push({
        label: `${monthNames[month]} ${year}`,
        value: `${year}-${String(month + 1).padStart(2, "0")}`,
      });
    }
  }

  return monthYearArray;
}

export const generateDateOptions = (baseId, isYearOnly = false) => {
  const userType = localStorage.getItem("userType");
  if (userType === "ADMIN") {
    return generateAllMonthYearArray();
  }

  const optionsFromLocalStorage = generateOptionsFromLocalStorage();
  if (optionsFromLocalStorage && optionsFromLocalStorage.length > 0) {
    return optionsFromLocalStorage;
  }

  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  let startMonth, startYear;

  switch (baseId) {
    case "appn8T3UykPjpTnV2":
      startMonth = 6;
      startYear = 2023;
      break;
    case "appkscyvJutKmwgNB":
      startMonth = 0;
      startYear = 2023;
      break;
    default:
      return generateAllMonthYearArray();
  }

  const options = [];
  let month = startMonth;
  let year = startYear;

  while (year < currentYear || (year === currentYear && month < currentMonth)) {
    options.push({
      label: `${monthNames[month]} ${year}`,
      value: `${year}-${String(month + 1).padStart(2, "0")}`,
    });

    month += 1;
    if (month > 11) {
      month = 0;
      year += 1;
    }
  }

  return isYearOnly
    ? [
        ...new Set(
          options.map((opt) => ({
            label: opt.label.split(" ")[1],
            value: opt.label.split(" ")[1],
          }))
        ),
      ]
    : options;
};

function generateAllYears() {
  const currentYear = new Date().getFullYear();
  const targetYear = 2017;
  const yearsArray = [];

  for (let year = currentYear; year >= targetYear; year--) {
    yearsArray.push(year);
  }

  return yearsArray;
}

const allYearsArray = generateAllYears();
export { allYearsArray as allYears, generateAllYears };

const currentMonthYear = () => {
  const currentDate = new Date();
  const currentMonth = monthNames[currentDate.getMonth()];
  const currentYear = currentDate.getFullYear();
  return `${currentMonth} ${currentYear}`;
};

export { currentMonthYear, generateAllMonthYearArray };
