import axios from "axios";

const API_URL = "https://book-luxury-backend.onrender.com/user";
// const API_URL = "http://localhost:3000/user";

const axiosInstance = axios.create({
  baseURL: API_URL,
});

export const addUser = async (user) => {
  try {
    return await axiosInstance.post("/users", user);
  } catch (error) {
    console.error("Error adding user:", error);
    throw error;
  }
};

export const updateUser = async (id, user) => {
  try {
    return await axiosInstance.put(`/users/${id}`, user);
  } catch (error) {
    console.error("Error updating user:", error);
    throw error;
  }
};

export const deleteUser = async (id) => {
  try {
    return await axiosInstance.delete(`/users/${id}`);
  } catch (error) {
    console.error("Error deleting user:", error);
    throw error;
  }
};

export const getUsers = async () => {
  try {
    return await axiosInstance.get("/users");
  } catch (error) {
    console.error("Error fetching users:", error);
    throw error;
  }
};
