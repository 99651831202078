import { Table } from "antd";
import React, { useState, useEffect } from "react";
import BlueCheck from "Assets/Icons/BlueCheck.jsx";
import MonthlyOverview from "Pages/Dashboard/Modals/MonthlyOverview/index.jsx";
import { fetchDataBillingMonth } from "redux/app/url.js";
import "../styles.js";
import formattedAmount from "util/formattedString.js";
import { useDispatch } from "react-redux";
import Airtable from "airtable";

const getData = (data) => {
  return data
    ?.map((d, i) => {
      const dates = d?.["Dates"];
      const dateParts = dates ? dates.split("-") : [];
      const startDate =
        dateParts.length > 0 ? new Date(dateParts[0]?.trim()) : null;
      const endDate =
        dateParts.length > 1 ? new Date(dateParts[1]?.trim()) : null;
      const sameMonth =
        startDate &&
        endDate &&
        startDate.getMonth() === endDate.getMonth() &&
        startDate.getFullYear() === endDate.getFullYear();

      return {
        key: i,
        dates: dates,
        unit_number: d?.["Unit No"],
        total_nights: d?.["Nights"] ?? "-",
        guest_name: d?.["Guest"]
          ?.replace(/[\\*#]/g, "") // Removes backslashes, asterisks, and hashes
          .replace(/\n/g, "") // Removes newline characters
          .trim(), // Trims any leading or trailing whitespace
        // Extract the first element from the "Owner Stay" array
        owner_occupied: d?.["Owner Stay"]?.[0],
        nightly_rate:
          d?.["Nightly Rate"] !== null &&
          d?.["Nightly Rate"] !== undefined &&
          d?.["Nightly Rate"] !== 0
            ? formattedAmount(d?.["Nightly Rate"], true)
            : "$0",

        booking_revenue:
          d?.["BookedRevenue"] !== null &&
          d?.["BookedRevenue"] !== undefined &&
          d?.["BookedRevenue"] !== 0
            ? formattedAmount(d?.["BookedRevenue"], true)
            : "$0",
        total_dates_reserved: sameMonth
          ? d?.["initialDatesRsrvd"] ?? null
          : d?.["initialDatesRsrvd"] ?? "-",
      };
    })
    .sort((a, b) => {
      const firstNumberA = parseInt(a.dates?.match(/(\d+)\s*-/)?.[1] || 0);
      const firstNumberB = parseInt(b.dates?.match(/(\d+)\s*-/)?.[1] || 0);
      return firstNumberA - firstNumberB;
    });
};

const columns = [
  {
    title: "Dates",
    dataIndex: "dates",
    key: "dates",
    className: "boldColumn",
  },
  {
    title: "Nights",
    dataIndex: "total_nights",
    key: "total_nights",
  },
  {
    title: "⁤  Unit No",
    dataIndex: "unit_number",
    key: "unit_number",
  },
  {
    title: "Guest Name",
    dataIndex: "guest_name",
    key: "guest_name",
    className: "boldColumn",
  },
  {
    title: "Booked  Revenue",
    dataIndex: "booking_revenue",
    key: "booking_revenue",
    className: "boldColumn",
  },
  {
    title: "Nightly Rate",
    dataIndex: "nightly_rate",
    key: "nightly_rate",
  },
  {
    title: "Owner  Occupied",
    dataIndex: "owner_occupied",
    key: "owner_occupied",
    render: (text) =>
      text ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "left",
            paddingLeft: "50px",
            height: "100%",
          }}
        >
          <BlueCheck color="#0098EE" />
        </div>
      ) : (
        <span className="empty-indicator">_</span>
      ),
  },
];

const TableContent = ({ month }) => {
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [billingData, setBillingData] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async (base) => {
      try {
        const data = await fetchDataBillingMonth(month, base, dispatch);
        setBillingData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const apiKey =
      "patfIHv7xmjArvAKG.8821159aa381f9044feeb21a631149370f60be5e8cbfe11ffde3ccec187f33ca";
    const baseId = localStorage.getItem("baseId");

    if (apiKey && baseId) {
      const base = new Airtable({ apiKey }).base(baseId);
      fetchData(base);
    }
  }, [month, dispatch]);

  const handleRowClick = (key) => {
    setSelectedRowKey(key);
    setSelectedRowData(billingData[key]);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectedRowKey(null); // Reset the selected row key when the modal closes
  };

  return (
    <>
      <div className="content-table-container">
        <Table
          dataSource={getData(billingData)}
          columns={columns}
          pagination={false}
          rowClassName={(record) =>
            record.key === selectedRowKey ? "selected-row" : ""
          }
          onRow={(record) => ({
            onClick: () => handleRowClick(record.key),
          })}
        />
      </div>

      <MonthlyOverview
        rowData={selectedRowData}
        visible={isModalVisible}
        onClose={handleModalClose}
      />
    </>
  );
};

export default TableContent;
