import { Row, Col } from "antd";
import { data } from "./data";
import CloseIcon from "Assets/Icons/CloseIcon";
import Print from "Assets/Icons/Print";
import { useEffect } from "react";

const ModelNavigation = ({ onCloseModal }) => {
  const handlePrintClick = () => {
    window.print();
  };

  useEffect(() => {
    const style = document.createElement("style");
    style.innerHTML = `
      @media print {
        body {
          margin: 0;
          padding: 0;
          width: 100%;
          background-color: #fff;
          color: #000;
          font-family: Arial, sans-serif;
        }

        .modal-content {
          width: 100%;
          max-width: 100%;
          margin: 0 auto;
          padding: 20px;
          box-sizing: border-box;
          overflow: visible;
          height: auto;
          page-break-inside: avoid;
        }

        .modal-content, .row, .card-holder, .rentel-income-card-holder {
          page-break-inside: avoid;
          break-inside: avoid;
          page-break-after: auto;
          overflow: visible;
        }

        .row {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 20px;
          page-break-inside: avoid;
        }

        .card-holder, .rentel-income-card-holder {
          page-break-after: always;
        }

        /* Landscape-specific adjustments */
        @media print and (orientation: landscape) {
          .modal-content {
            padding: 20px;
            font-size: 14pt;
          }

          .model-title, .card-title, .card-value {
            font-size: 14pt;
          }

          body {
            margin: 1cm;
            transform: rotate(0);
          }

          .row {
            display: block;
            width: 100%;
            break-inside: avoid;
          }

          .card-holder, .rentel-income-card-holder {
            page-break-after: auto;
          }
        }

        /* Portrait-specific adjustments */
        @media print and (orientation: portrait) {
          .modal-content {
            padding: 20px;
            font-size: 10pt;
            width: auto;
          }

          .model-title, .card-title, .card-value {
            font-size: 10pt;
          }

          body {
            margin: 1cm;
          }

          .row {
            display: block;
            width: 100%;
            break-inside: avoid;
          }

          .card-holder, .rentel-income-card-holder {
            page-break-after: auto;
          }
        }
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);

  return (
    <Row justify={"end"}>
      {data(onCloseModal, handlePrintClick).map((item, index) => (
        <Col key={index} xl={1}>
          <div style={{ cursor: "pointer" }} onClick={item.onClick}>
            <item.Icon />
          </div>
        </Col>
      ))}
    </Row>
  );
};

export default ModelNavigation;
