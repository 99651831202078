import React, { useState, useEffect } from "react";
import { Layout, Badge, Tooltip, Row, Col } from "antd";
import FirstRow from "./FirstRow/FirstRow";
import OtherRows from "./OtherRows";
import "./summary.css";
import fetchRecordsSummary from "redux/app/url";
import InfoCircle from "Assets/Icons/InfoCircle";
import { useSelector, useDispatch } from "react-redux";
import PageLoader from "Components/Shared/PageSpinner";
import Airtable from "airtable";
import { setLoading } from "redux/app/reducers/tabsSlice";



const Summary = ({ yearSelected, baseId }) => {
  const [data, setData] = useState(null);
  const loading = useSelector((state) => state.tabs.loading);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async (base) => {
      dispatch(setLoading(true));
      try {
        const result = await fetchRecordsSummary(`${yearSelected}`, base);
        console.log("Fetched data:", result);
        setData(result);
        console.log("Data we have for fisrt row:", data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        dispatch(setLoading(false));
      }
    };

    const apiKey =
      "patfIHv7xmjArvAKG.8821159aa381f9044feeb21a631149370f60be5e8cbfe11ffde3ccec187f33ca";
    const baseIdToUse = localStorage.getItem("baseId") || baseId;

    if (apiKey && baseIdToUse) {
      console.log("API Key:", apiKey);
      console.log("Base ID:", baseIdToUse);

      const base = new Airtable({
        apiKey: apiKey,
      }).base(baseIdToUse);

      fetchData(base);
    } else {
      dispatch(setLoading(false));
      console.error("API Key or Base ID is missing");
    }
  }, [yearSelected, baseId, dispatch]);
  console.log("Data we have for fisrt row:", data);

  return (
    <div style={{ position: "relative" }}>
      {loading && <PageLoader />} {/* Show PageLoader while loading */}
      <Row justify="space-between" className="summary-header">
        <Col>
          <div className="summary-heading-text">
            Summary {yearSelected}
            <Tooltip
              title={
                <div className="tooltip-left-align">
                  *Summary values are in Year-to-Date format and include
                  upcoming bookings.
                </div>
              }
            >
              <Badge count={<InfoCircle />} />
            </Tooltip>
          </div>
        </Col>
        <Col></Col>
      </Row>
      <div>
        {data && !loading ? (
          <>
            <FirstRow data={data} />
            <OtherRows data={data} />
          </>
        ) : null}
      </div>
    </div>
  );
};

export default Summary;
