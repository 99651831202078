import LoadingSpinner from "Components/Shared/LoadingSpinner";
import formattedAmount from "util/formattedString";
import getPercentValue from "util/getPercentage";

const formatValue = (value, isCurrency = false) => {
  console.log("formatValue called with:", value, isCurrency);
  if (
    value === null ||
    value === undefined ||
    value === "" ||
    value === "-" ||
    value === "_" ||
    value === 0
  ) {
    return isCurrency ? "$0" : "0";
  }
  return isCurrency ? formattedAmount(value, true) : value;
};

export const data = (cardsData) => {
  console.log("cardsData:", cardsData);
  const guestNights =
    typeof cardsData?.["Guest Nights"] === "number"
      ? cardsData["Guest Nights"]
      : 0;
  const ownerNights =
    typeof cardsData?.["Owner Nights"] === "number"
      ? cardsData["Owner Nights"]
      : 0;

  // Calculate Nights Occupied as the sum of Guest Nights and Owner Nights
  const nightsOccupied = guestNights + ownerNights;

  return [
    {
      key: 1,
      card: [
        {
          cardkey: 1,
          cardtitle: "Nights Occupied",
          tooltipTitile: `Nights Occupied = guest nights + owner nights (if any) for the month`,
          cardvalue: formatValue(nightsOccupied),
        },
        {
          cardkey: 2,
          cardtitle: "Avg Nightly Rate",
          cardvalue: formatValue(cardsData?.["Nightly Rate"], true),
        },
        {
          cardkey: 3,
          cardtitle: "Occupancy Rate",
          tooltipTitile: `Split unit rentals may yield monthly occupancy rates greater than 100%`,
          cardvalue: cardsData?.["Mthly Occupancy Rate²"]
            ? getPercentValue(cardsData?.["Mthly Occupancy Rate²"])
            : "0%",
        },
      ],
    },
    {
      key: 2,
      card: [
        {
          cardkey: 4,
          cardtitle: "Monthly Revenue",
          cardvalue: formatValue(cardsData?.["Gross Revenue"], true),
        },
        {
          cardkey: 5,
          cardtitle: "Lodging Tax",
          cardvalue: formatValue(cardsData?.["Lodging Tax"], true),
        },
        {
          cardkey: 6,
          cardtitle: "Net of Taxes",
          cardvalue: formatValue(cardsData?.["Net of Taxes"], true),
        },
      ],
    },
    {
      key: 3,
      card: [
        {
          cardkey: 7,
          cardtitle: "Owner revenue",
          cardvalue: formatValue(cardsData?.["Owner Revenue"], true),
        },
        {
          cardkey: 8,
          cardtitle: "HOA Fees",
          tooltipTitile: `HOA Fees include any outstanding balances from prior months & are netted from the Owner Revenue`,
          cardvalue: formatValue(cardsData?.["HOA Fees³"], true),
        },
        {
          cardkey: 9,
          cardtitle: "HOA Balance",
          tooltipTitile: `HOA Balance(s), if any, roll forward to subsequent months until fully settled`,
          cardvalue: formatValue(cardsData?.["HOA Balance⁴"], true),
        },
        {
          cardkey: 10,
          cardtitle: "Owner Payout",
          tooltipTitile: `Net of HOA costs`,
          cardvalue: formatValue(cardsData?.["Owner Payout⁵"], true),
        },
      ],
    },
  ];
};
