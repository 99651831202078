import axios from "axios";
import { requestStart, requestCompleted, setApiData } from "../..";
import { notification } from "antd";
import { setSelectedUnit, setUnitsList } from "redux/app/reducers/tabsSlice";

export function LoginAction(navigate, user) {
  console.log("Received navigation and user data in action", {
    navigate,
    user,
  });

  return async (dispatch) => {
    try {
      dispatch(requestStart());
      console.log("Sending user data to the API", user);

      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_LOGIN}`,
          user
        )
        .then((res) => {
          console.log("API response received", res.data.data.user.unite);
          console.log("API response received", res);

          // Use apiKey from the response or fallback to the environment variable
          const apiKey =
            res.data?.data?.user?.apikey ||
            process.env.REACT_APP_AIRTABLE_API_KEY;

          // Log the apiKey to check if it's present
          console.log("API Key:", apiKey);

          // Handle the baseId depending on whether it's a regular user or an admin
          const baseId =
            res.data?.data?.user?.unite ||
            res.data?.data?.user?.apiData?.baseId;

          // Log the selected baseId
          console.log("Base ID:", baseId);

          // If baseId is still undefined, throw an error and log it
          if (!baseId) {
            console.error("Base ID is missing");
          }

          if (res) {
            // Save tokens and other user data to local storage
            console.log(res, "res we are getting authenticated user data");
            localStorage.setItem("access_token", res.data.data.accessToken);
            localStorage.setItem("apiKey", apiKey); // Save apiKey from response or env
            localStorage.setItem("baseId", baseId); // Save the correct baseId
            localStorage.setItem("user_email", res.data.data.user.email);
            localStorage.setItem("user_name", res.data.data.user.name);
            localStorage.setItem(
              "months",
              JSON.stringify(res.data.data.user.months)
            );
            localStorage.setItem(
              "years",
              JSON.stringify(res.data.data.user.years)
            );

            localStorage.setItem("userType", res.data.data.user.airTableType);

            localStorage.setItem("show", false);
            console.log("User data saved to local storage");

            dispatch(setApiData(res.data.data.user.apiData));
            dispatch(setSelectedUnit(baseId)); // Use the same baseId in dispatch

            dispatch(requestCompleted());

            // Navigation to the dashboard
            console.log("Navigating to dashboard");
            navigate("/dashboard");

            // Show success notification
            notification.success({
              message: "Login successful",
            });
          }
        })
        .catch((error) => {
          console.error("Error during API call:", error);
          dispatch(requestCompleted());
          notification.error({
            message: "Your email and password do not match",
          });
        });
    } catch (error) {
      console.error("Unexpected error:", error);
      dispatch(requestCompleted());
    }
  };
}

export function ForgetPasswordAction(navigate, user) {
  return async (dispatch) => {
    try {
      dispatch(requestStart());
      axios
        .patch(
          `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_FORGET_PASSWORD}`,
          user
        )
        .then((res) => {
          if (res) {
            localStorage.setItem("passwordUpdate", false);
            dispatch(requestCompleted());
            navigate("/notification");
          }
        })
        .catch((error) => {
          dispatch(requestCompleted());
          notification.error({
            message: error?.response?.data?.message,
          });
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}

export function ResetPasswordAction(navigate, obj, token) {
  return async (dispatch) => {
    try {
      dispatch(requestStart());
      axios
        .patch(
          `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_RESET_PASSWORD}`,
          obj,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res) {
            localStorage.setItem("passwordUpdate", true);
            dispatch(requestCompleted());
            navigate("/notification");
          }
        })
        .catch((error) => {
          dispatch(requestCompleted());
          notification.error({
            message: error?.response?.data?.message,
          });
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}

export function ChangePasswordAction(navigate, showModal = () => {}, obj) {
  return async (dispatch) => {
    try {
      dispatch(requestStart());
      axios
        .patch(
          `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_CHANGE_PASSWORD}`,
          obj,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        )
        .then((res) => {
          if (res) {
            console.log(res, "res");
            localStorage.setItem("passwordUpdate", true);
            dispatch(requestCompleted());
            showModal();
          }
        })
        .catch((error) => {
          dispatch(requestCompleted());
          notification.error({
            message: error?.response?.data?.message,
          });
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}
export function SignUpAction(user, closeDrawer) {
  return async (dispatch) => {
    try {
      dispatch(requestStart());
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SIGNUP}`,
          user
        )
        .then((res) => {
          if (res) {
            dispatch(requestCompleted());
            notification.success({
              message: "Owner registered successfully!",
            });
            closeDrawer();
          }
        })
        .catch((error) => {
          dispatch(requestCompleted());
          notification.error({
            message: error?.response?.data?.message,
          });
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}
export function TableListAction() {
  return async (dispatch) => {
    try {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_AIR_TABLE_IDS_LIST}`
        )
        .then((res) => {
          if (res) {
            dispatch(setUnitsList(res.data));
          }
        })
        .catch((error) => {
          dispatch(requestCompleted());
          notification.error({
            message: error?.response?.data?.message,
          });
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}
