import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import { store } from "redux/store";
import "antd/dist/antd.min.css";
import "./App.less";

import { ParentWrapper } from "Styles/global";
import { GlobalStyles } from "global";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ParentWrapper>
        <GlobalStyles />
        <App />
      </ParentWrapper>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
