import { Bar } from "react-chartjs-2";
import "./chart.css";
import ChartDataLabels from "chartjs-plugin-datalabels";

const BarChart = ({ chartData }) => {
  const data = {
    labels: chartData?.map((each) => each.label),

    datasets: [
      {
        label: "Revenue",

        backgroundColor: "#0098EE",
        borderColor: "rgba(75,192,192,1)",
        borderWidth: 1,
        // hoverBackgroundColor: "rgba(75,192,192,0.6)",
        // hoverBorderColor: "rgba(75,192,192,1)",
        data: chartData?.map((each) => each.value),
        barThickness: 45,
        borderRadius: 8,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    height: 50,
    scales: {
      y: {
        grid: {
          display: true,
        },
        ticks: {
          maxTicksLimit: 6,
          callback: function (value) {
            if (value >= 1000) {
              return value / 1000 + "k";
            }
            return value;
          },
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      // tooltip: {
      //   displayColors: false,
      //   useHTML: true,
      //   backgroundColor: "white",
      //   titleColor: "black",
      //   bodyColor: "black",
      //   titleFont: "12px",
      //   borderColor: "#D3D3D3",
      //   borderWidth: 1,
      //   callbacks: {
      //     title: function (context) {
      //       return `Month                                     ${context[0].label}`;
      //     },
      //     label: function (context) {
      //       return `Sum: Revenue Split(70%):   ${formattedAmount(
      //         context.parsed.y
      //       )}`;
      //     },
      //   },
      // },
      datalabels: {
        anchor: "end",
        align: "top",
        color: "#000",
        formatter: function (value) {
          return "$" + Number(value).toLocaleString();
        },
        padding: {
          top: -5,
        },
      },
    },
    layout: {
      padding: 24,
    },
  };

  return (
    <div className="chart-section">
      <div className="chart-main-heading">Owner Income per Month</div>
      <div className="chart-sub-heading">YTD</div>
      <div className="chart-wrapper">
        <Bar data={data} options={options} plugins={[ChartDataLabels]} />
      </div>
    </div>
  );
};

export default BarChart;
