import addDollar from "./dollarSign";
import truncatedNumber from "./truncateNumber";

const formattedAmount = (amount, removeDecimal = false) => {
  if (amount === 0) return "$0";
  if (!amount) return "-";
  const numberWithCommas = removeDecimal
    ? `${Math.round(amount)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    : `${truncatedNumber(amount)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return addDollar(numberWithCommas);
};

export default formattedAmount;
