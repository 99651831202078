import SubHeading from "Components/Shared/SubHeading";
import TableContent from "./TableContent";
import "./styles.js";
import { TableWrapper } from "./styles.js";

const RecapTable = (props) => {
  return (
    <TableWrapper style={{ marginTop: "60px" }}>
      <SubHeading className="SectionHeading">Booking Entries</SubHeading>
      <div
        style={{
          color: "#6c757d",
          fontSize: "14px",
          marginBottom: "16px",
          marginTop: "-10px",
          fontFamily: "'Montserrat' !important",
        }}
      >
        Select a booking to see Guest details and Rental Income.
      </div>

      <TableContent {...props} />
    </TableWrapper>
  );
};

export default RecapTable;
