import React, { ReactNode } from "react";
import styled from "styled-components";

// Define the styled component
export const ButtonModelWrapper = styled.button`
  text-align: center;
  color: #110f0e;
  background: #f8f1b6;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  margin-top: 20px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  padding: 8px 10px 8px 10px;
  gap: 5px;
  display: flex;
  height: fit-content;
  margin-bottom: 12px;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 0px 1px 1px;

  &:hover {
    background: #ffd936; /* Change this to your preferred hover background color */
  }

  .link-icon {
    padding: 2px 2px 0px;
  }
`;
