import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  changeSelectedMonth,
  selectedMonth,
} from "redux/app/reducers/tabsSlice";
import {
  generateDateOptions,
  generateAllMonthYearArray,
} from "../../../util/generateYear";

const { Option } = Select;
const defaultBaseId = "appkscyvJutKmwgNB";
const formatMonthYear = (dateString) => {
  const date = new Date(`${dateString}-01`);
  const options = { year: "numeric", month: "long" };
  return date.toLocaleDateString("en-US", options);
};

export const DropDownMonthly = ({ ...props }) => {
  const [options, setOptions] = useState(
    JSON.parse(localStorage.getItem("dropdownOptions")) || [] // Initialize from local storage if available
  );
  const [baseId, setBaseId] = useState(
    localStorage.getItem("baseId") || defaultBaseId
  );
  const dispatch = useDispatch();
  const userType = localStorage.getItem("userType");
  const monthSelected = useSelector(selectedMonth);

  useEffect(() => {
    const updateOptions = () => {
      const latestBaseId = localStorage.getItem("baseId") || defaultBaseId;
      if (latestBaseId !== baseId) {
        setBaseId(latestBaseId);
      }

      let generatedOptions = [];
      if (userType === "ADMIN") {
        generatedOptions = generateAllMonthYearArray();
      } else {
        generatedOptions = generateDateOptions(latestBaseId);
      }

      // Sort options by value in descending order
      generatedOptions.sort((a, b) => (a.value < b.value ? 1 : -1));

      setOptions(generatedOptions);
      localStorage.setItem("dropdownOptions", JSON.stringify(generatedOptions)); // Store options in local storage
    };

    updateOptions();
    console.log("Month selected on load", monthSelected);
    if (monthSelected) {
      const formattedMonthYear = formatMonthYear(monthSelected);
      console.log("Month selected on load and calling", formattedMonthYear);
      if (formattedMonthYear) {
        handleChange(formattedMonthYear);
      }
    }
  }, [baseId, userType]);

  const handleChange = (value) => {
    console.log("Value in handleChange we got", value);
    const selectedOption = options.find((option) => option.value === value);
    console.log("Selected option in handleChange", selectedOption);
    if (selectedOption) {
      dispatch(changeSelectedMonth(selectedOption.label));
    } else {
      dispatch(changeSelectedMonth(value));
    }
    localStorage.setItem("selectedMonth", value); // Store selected month in local storage
  };

  useEffect(() => {
    console.log("Options updated", options);
    console.log("Month selected", monthSelected);

    if (options.length > 0 && monthSelected !== options[0].value) {
      const mostRecentOption = options[0].value;
      console.log("Most recent option", mostRecentOption);
      if (!monthSelected) {
        handleChange(mostRecentOption); // Automatically select the most recent option
      }
    }
  }, [options]);

  return (
    <Select
      showSearch={false}
      className="header-custom-select"
      onChange={handleChange}
      value={
        options.find((option) => option.label === monthSelected)?.value ||
        options[0]?.value // Ensure the most recent month is displayed by default
      }
      placeholder="Select a month"
    >
      {options.map((each) => (
        <Option value={each.value} key={each.value}>
          {each.label}
        </Option>
      ))}
    </Select>
  );
};
