import React from "react";
import formattedAmount from "util/formattedString";

export const data = (val) => {
  const dates = val?.["Dates"];
  const dateParts = dates ? dates.split("-") : [];
  const startDate =
    dateParts.length > 0 ? new Date(dateParts[0]?.trim()) : null;
  const endDate = dateParts.length > 1 ? new Date(dateParts[1]?.trim()) : null;
  const sameMonth =
    startDate &&
    endDate &&
    startDate.getMonth() === endDate.getMonth() &&
    startDate.getFullYear() === endDate.getFullYear();
  const totalDatesReserved = val?.["initialDatesRsrvd"];
  console.log("here is the val we have ", val);
  // Custom trim function to remove unwanted characters
  const cleanGuestName = (name) => {
    return name ? name.replace(/[*#\\]/g, "").trim() : "";
  };

  const cards = [
    {
      cardtitle: "Guest",
      cardvalue: cleanGuestName(val?.["Guest"]),
    },
    { cardtitle: "Nights", cardvalue: val?.["Nights"] ?? "-" },
    {
      cardtitle: "Unit Number",
      cardvalue: val?.["Unit No"] ?? "-",
    },
    {
      cardtitle: "Year",
      cardvalue: val?.["Year"]?.replace(/[*#]/g, "").trim(),
    },
  ];

  if (totalDatesReserved && !sameMonth) {
    cards.push({
      cardtitle: "Total dates reserved",
      cardvalue: totalDatesReserved,
    });
  }

  return [
    {
      key: 1,
      card: cards,
    },
    {
      key: 3,
      card: [
        {
          cardtitle: "Booked Revenue",
          cardvalue:
            val?.["Booked Revenue"] && val?.["Booked Revenue"] !== 0
              ? formattedAmount(val?.["Booked Revenue"], false)
              : "$0",
        },
        {
          cardtitle: "Lodging Tax",
          cardvalue:
            val?.["Lodging Tax"] && val?.["Lodging Tax"] !== 0
              ? formattedAmount(val?.["Lodging Tax"], false)
              : "$0",
        },
        {
          cardtitle: "Net of Taxes",
          cardvalue:
            val?.["Net Revenue"] && val?.["Net Revenue"] !== 0
              ? formattedAmount(val?.["Net Revenue"], false)
              : "$0",
        },
        {
          cardtitle: "Owner revenue",
          cardvalue:
            val?.["Owner Revenue"] && val?.["Owner Revenue"] !== 0
              ? formattedAmount(val?.["Owner Revenue"], false)
              : "$0",
        },
      ],
    },
  ];
};
