import { Image } from "antd";
import "../summary.css";

const SingleCard = ({ Icon, value, title }) => {
  return (
    <div className="summary-single-card-container">
      <div className="first-row-card-title">{title}</div>
      <div className="summary-single-card-value">{value || "$0"}</div>
    </div>
  );
};

export default SingleCard;
