import React, { useState, useEffect } from "react";
import { notification } from "antd";
import { Typography } from "antd";

import {
  Layout,
  Button,
  Drawer,
  Form,
  Input,
  message,
  Row,
  Col,
  Select,
} from "antd";

import UserTable from "Components/Shared/Tables/userTable";
import {
  addUser,
  updateUser,
  getUsers,
  deleteUser,
} from "apiLayer/userService";
import AppLayout from "Layout/Layout";
import CustomDropdown from "Components/Shared/CustomDropDown";
import PageLoader from "Components/Shared/PageSpinner";
import CustomButton from "Components/Shared/PrimaryButton";
import { FilterOutlined } from "@ant-design/icons";

const { Content } = Layout;

const ManageUsers = () => {
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [isFilterDrawerVisible, setIsFilterDrawerVisible] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const { Title, Text } = Typography;
  const [userId, setUserId] = useState("");
  const [users, setUsers] = useState([]);
  const [form] = Form.useForm();
  const [filterForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [isAdminRole, setIsAdminRole] = useState(false); // Track if the selected role is Admin

  useEffect(() => {
    fetchUsers();
  }, []);

  const generateMonthOptions = (startYear, startMonth) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const currentDate = new Date();
    const options = [];
    let year = startYear;
    let month = startMonth;

    while (
      year < currentDate.getFullYear() ||
      (year === currentDate.getFullYear() && month <= currentDate.getMonth())
    ) {
      options.push({
        label: `${months[month]} ${year}`,
        value: `${months[month]} ${year}`,
      });
      month++;
      if (month === 12) {
        month = 0;
        year++;
      }
    }

    return options;
  };
  // const handleRoleChange = (value) => {
  //   setIsAdminRole(value === "ADMIN"); // Set the admin role state based on the selected value
  //   if (value === "ADMIN") {
  //     form.setFieldsValue({ unitNumber: "appn8T3UykPjpTnV2" }); // Automatically set the default unit for admins
  //   } else {
  //     form.setFieldsValue({ unitNumber: undefined }); // Clear unitNumber when not admin
  //   }
  // };

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await getUsers();
      const data = response.data; // Extract data from response
      setUsers(data);
    } catch (error) {
      message.error("Failed to fetch users");
    } finally {
      setLoading(false);
    }
  };

  const showDrawer = () => {
    setIsDrawerVisible(true);
  };

  const showFilterDrawer = (user) => {
    filterForm.setFieldsValue({
      name: user.name,
      email: user.email,
      months: user.months,
      years: user.years,
    });
    setUserId(user?._id);
    setIsFilterDrawerVisible(true);
  };

  const options = generateMonthOptions(2023, 0);

  const handleCancel = () => {
    setIsDrawerVisible(false);
    setEditingUser(null);
    form.resetFields();
    setIsAdminRole(false); // Reset admin role state when drawer is closed
  };

  const handleEdit = (user) => {
    // Sync all three fields: unitNumber, unite, and apiData.baseId
    const unitValue = user.apiData?.baseId || user.unitNumber || user.unite;

    console.log("Editing user original data:", user);
    console.log("Unit value for sync:", unitValue);

    setEditingUser({
      ...user,
      unitNumber: unitValue,
      unite: unitValue,
      apiData: { baseId: unitValue },
    });

    // Set form values for editing, ensuring all three fields are in sync
    form.setFieldsValue({
      ...user,
      password: "",
      airTableType: user.airTableType,
      unitNumber: unitValue, // Sync unitNumber with apiData.baseId and unite
      unite: unitValue, // Sync unite with unitNumber and apiData.baseId
    });

    showDrawer();

    // Log form values after setting them
    console.log("Form values after setting for edit:", form.getFieldsValue());
  };

  const handleFilterCancel = () => {
    setIsFilterDrawerVisible(false);

    filterForm.resetFields();
  };

  const handleSave = async (values) => {
    setLoading(true);

    // Log values received from the form
    console.log("Values received on save:", values);

    try {
      // Sync all three fields during save
      const updatedValues = {
        ...values,
        apiData: { baseId: values.unitNumber },
        role: values.airTableType,
        unitNumber: values.unitNumber, // Ensure unitNumber is updated
        unite: values.unitNumber, // Sync unite with unitNumber and apiData.baseId
      };

      console.log("Updated values to save:", updatedValues);

      if (editingUser) {
        // Sync all fields when updating
        editingUser.apiData.baseId = updatedValues.unitNumber;
        editingUser.unitNumber = updatedValues.unitNumber;
        editingUser.unite = updatedValues.unitNumber;

        console.log("Editing user final data before update:", editingUser);

        await updateUser(editingUser._id, updatedValues);
        message.success("User updated successfully");
      } else {
        // Add a new user
        await addUser(updatedValues);
        message.success("User added successfully");
      }

      fetchUsers();
      handleCancel();
    } catch (error) {
      message.error("Failed to save user");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      await deleteUser(id);
      message.success("User deleted successfully");
      fetchUsers();
    } catch (error) {
      message.error("Failed to delete user");
    } finally {
      setLoading(false);
    }
  };

  const handleFilterSave = async (values) => {
    setLoading(true);
    try {
      await updateUser(userId, values);
      fetchUsers();
      notification.success({
        description: "Filters applied successfully.",
        placement: "topRight",
      });
      handleFilterCancel();
    } catch (error) {
      notification.error({
        description: "Failed to apply filters. Please try again.",
        placement: "topRight",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <AppLayout>
      <Content
        style={{
          margin: "16px",
          marginTop: "45px",
          paddingRight: "30px",
          paddingLeft: "25px",
        }}
      >
        {loading && <PageLoader />}
        <Row
          justify="space-between"
          align="middle"
          style={{ marginBottom: 16 }}
        >
          <Col>
            <h1 style={{ fontSize: "36px", fontFamily: "Montserrat" }}>
              Manage Users
            </h1>
          </Col>
          <Col>
            <span onClick={showDrawer}>
              <CustomButton type="primary">Add User</CustomButton>
            </span>
          </Col>
        </Row>
        <div style={{ marginBottom: "100px" }}>
          <UserTable
            onEdit={handleEdit}
            onDelete={handleDelete}
            onFilter={showFilterDrawer}
            users={users}
          />
        </div>
        <Drawer
          title={
            <Title
              level={3}
              style={{
                fontFamily: "Montserrat",
                fontSize: "28px",
                fontWeight: 600,
                lineHeight: "28px",
                textAlign: "left",
              }}
            >
              {editingUser ? "Edit User" : "Add User"}
            </Title>
          }
          onClose={handleCancel}
          visible={isDrawerVisible}
          closable={false}
        >
          <Form
            form={form}
            layout="vertical"
            onFinish={(values) => {
              // If the role is "Admin" and adding a new user, set the default unit value
              if (!editingUser && values.airTableType === "ADMIN") {
                values.unitNumber = "appn8T3UykPjpTnV2"; // Set default unit for admins
              }
              handleSave(values);
            }}
            style={{ paddingRight: "0px" }}
          >
            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true, message: "Please input the name" }]}
            >
              <Input
                placeholder="Enter user's name"
                style={{
                  border: "1px solid #d9d9d9",
                  borderRadius: "8px",
                  height: "40px",
                  padding: "8px 12px",
                }}
              />
            </Form.Item>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  message: "Please input the email",
                },
                {
                  type: "email",
                  message: "Please enter a valid email",
                },
              ]}
            >
              <Input
                placeholder="Enter user's email"
                style={{
                  border: "1px solid #d9d9d9",
                  borderRadius: "8px",
                  height: "40px",
                  padding: "8px 12px",
                }}
              />
            </Form.Item>
            <Form.Item
              name="password"
              label="Password"
              rules={[{ required: true, message: "Please input the password" }]}
            >
              <Input.Password
                placeholder="Enter user's password"
                style={{
                  border: "1px solid #d9d9d9",
                  borderRadius: "8px",
                  height: "40px",
                  padding: "8px 12px",
                }}
              />
            </Form.Item>

            {/* Role Dropdown */}

            <Form.Item
              name="airTableType"
              label="Role"
              rules={[
                { required: true, message: "Please select the user type" },
              ]}
            >
              <CustomDropdown
                placeholder="Select user type"
                options={[
                  { label: "Admin", value: "ADMIN" },
                  { label: "Owner", value: "OWNER" },
                ]}
                value={form.getFieldValue("airTableType")}
                onChange={(value) => {
                  form.setFieldsValue({ airTableType: value });

                  // If Admin is selected, set the default value for unitNumber and hide/disable the field
                  if (value === "ADMIN") {
                    form.setFieldsValue({ unitNumber: "appn8T3UykPjpTnV2" });
                    setIsAdminRole(true); // Update state to hide Unit Access field
                  } else {
                    setIsAdminRole(false); // Show Unit Access field for Owner role
                  }
                }}
              />
            </Form.Item>

            {/* Unit Access Field - Conditionally rendered */}
            {!isAdminRole && (
              <Form.Item
                name="unitNumber"
                label="Unit Access"
                rules={[
                  {
                    required: !isAdminRole,
                    message: "Please select the unit number",
                  },
                ]}
              >
                <CustomDropdown
                  placeholder={
                    form.getFieldValue("airTableType") === "ADMIN"
                      ? "All Units"
                      : "Select unit number"
                  }
                  options={[
                    { label: "611/613", value: "appkscyvJutKmwgNB" },
                    { label: "723/725", value: "appn8T3UykPjpTnV2" },
                  ]}
                  value={form.getFieldValue("unitNumber")}
                  onChange={(value) =>
                    form.setFieldsValue({ unitNumber: value })
                  }
                  disabled={form.getFieldValue("airTableType") === "ADMIN"} // Disable the field when Admin is selected
                />
              </Form.Item>
            )}

            {/* Buttons */}
            <Row gutter={16}>
              <Col span={12}>
                <Button
                  onClick={handleCancel}
                  block
                  style={{ height: "40px", borderRadius: "8px" }}
                >
                  Cancel
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  onClick={() => form.submit()}
                  type="primary"
                  block
                  style={{
                    height: "40px",
                    borderRadius: "8px",
                    backgroundColor: "#ffd936 !important",
                    color: "black",
                    border: "none",
                  }}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        </Drawer>

        <Drawer
          title={
            <div>
              <Title
                level={3}
                style={{
                  fontFamily: "Montserrat",
                  fontSize: "28px",
                  fontWeight: 600,
                  lineHeight: "16px",
                  textAlign: "left",
                }}
              >
                Filters
              </Title>
              <Text
                style={{
                  fontFamily: "Montserrat",
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "14.63px",
                  letterSpacing: "-0.02em",
                  textAlign: "left",
                }}
              >
                Show or hide specific months & years for this user.
              </Text>
            </div>
          }
          onClose={handleFilterCancel}
          visible={isFilterDrawerVisible}
          closable={false}
          className="drawer-wrapper"
        >
          <Form form={filterForm} layout="vertical" onFinish={handleFilterSave}>
            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true, message: "Please input the name" }]}
            >
              <Input
                placeholder="Enter user's name"
                style={{
                  border: "1px solid #d9d9d9",
                  borderRadius: "8px",
                  height: "40px",
                  padding: "8px 12px",
                }}
                disabled
              />
            </Form.Item>
            <Form.Item
              name="email"
              label="Email"
              rules={[{ required: true, message: "Please input the email" }]}
            >
              <Input
                placeholder="Enter user's email"
                style={{
                  border: "1px solid #d9d9d9",
                  borderRadius: "8px",
                  height: "40px",
                  padding: "8px 12px",
                }}
                disabled
              />
            </Form.Item>
            <Form.Item name="months" label="Months">
              <Select
                placeholder="Select months"
                options={options}
                mode="multiple"
              />
            </Form.Item>
            <Form.Item name="years" label="Years">
              <Select
                placeholder="Select years"
                options={[
                  { label: "2023", value: "2023" },
                  { label: "2024", value: "2024" },
                ]}
                mode="multiple"
              />
            </Form.Item>
            <Row gutter={16}>
              <Col span={12}>
                <Button
                  onClick={handleFilterCancel}
                  block
                  style={{ height: "40px", borderRadius: "8px" }}
                >
                  Cancel
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  onClick={() => filterForm.submit()}
                  type="primary"
                  block
                  style={{
                    height: "40px",
                    borderRadius: "8px",
                    backgroundColor: "#ffd936 !important",
                    color: "black",
                    border: "none",
                  }}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        </Drawer>
      </Content>
    </AppLayout>
  );
};

export default ManageUsers;
