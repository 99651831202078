import styled from "styled-components";
import { Modal } from "antd";
export const PreviewModelWrapper = styled.div`
  padding: 20px 0px;
  display: flex;
  flex-direction: column;
  &.center {
    text-align: center;
  }
  .cards-holder {
    flex-direction: row;
    gap: 8px;
  }
  .black-line {
    width: 100%;
    height: 2px; /* Adjust the height to make the line thicker or thinner */
    background-color: black;
    margin-top: 8px; /* Adjust the margin as needed */
    margin-bottom: 16px; /* Adjust the margin as needed */
  }
`;

export const MonthlyPreviewModal = styled(Modal)`
  && {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    min-width: 720px;
  }

  .ant-modal-content {
    height: 120vh;
    min-width: 720px;
    width: 100%;
    border-radius: 0;
    padding: 0px 44px;

    overflow: scroll;
  }
  .ant-modal-body {
    padding: 0px 0px 0px 0px;
  }
  .model-title {
    width: 100%;
    padding-bottom: 8px;
    margin-bottom: 16px;
    font-size: 24px;
    font-weight: 500;
    font-family: "Montserrat";
    color: #110f0e;
    border-bottom: 1px solid #cccccc;
  }
  .ant-modal-header {
    padding: 0px 0px 44px 0px;
    border-bottom: 1px solid #cccccc;
    margin-bottom: 12px;
  }
  .ant-modal-close-x {
    display: none;
  }

  .green-circle {
    padding: 3px 4px;
    width: fit-content;
  }
  .butn-holder {
    display: flex;
    justify-content: end;
    height: 100%;
  }
  .header-icon-holder {
    cursor: pointer;
  }
  .model-data-row {
    padding: 20px 20px 28px 20px;
    background: #fafafa;
    border: 1px solid #e7e7e7;
    border-radius: 8px;
    margin-bottom: 20px;
  }
`;
