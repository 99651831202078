import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectedUnit, unitsList } from "redux/app/reducers/tabsSlice.js";
import "./user_info.css";

const UserInfo = () => {
  const activeUnit = useSelector(selectedUnit);
  const allUnits = useSelector(unitsList);
  const userType = localStorage.getItem("userType");

  const unit = allUnits?.find((item) => {
    if (userType === "ADMIN") {
      return item.id === activeUnit;
    } else {
      const baseId = localStorage.getItem("baseId");
      return item.id === baseId;
    }
  });

  useEffect(() => {
    if (unit && unit.name) {
      localStorage.setItem("unitName", unit.name);
    }
  }, [unit]);

  return (
    <div className="user-info-container">
      <div className="yearly-user-info-user-name">
        Owner Name:{" "}
        {unit?.name === "Unit 723 / 725"
          ? "Adcox"
          : unit?.name === "Unit 611 / 613" && "Fuqua"}
      </div>
      <div className="address-container">
        <div className="user-info-address">
          {`${unit?.name.trim()} | The Ritz-Carlton Residence`}
        </div>
      </div>
    </div>
  );
};

export default UserInfo;
