import "./header.css";
import { Select } from "antd";
import yearsList from "util/getYearsList";
import { Drawer, Button, Input, Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import UserInfo from "./UserInfo/index";
import yearly from "Assets/Icons/yearly_cal.svg";

import { loading as stateLoading } from "redux/app";
import { SignUpAction } from "redux/app/Actions/Auth";
import CustomButton from "Components/Shared/PrimaryButton";
import {
  changeSelectedYear,
  selectedYear,
  unitsList,
} from "redux/app/reducers/tabsSlice";
import PageLoader from "Components/Shared/PageSpinner";

const { Option } = Select;
const optionsArr = yearsList;

const DropDown = ({ setYear }) => {
  const dispatch = useDispatch();
  const yearSelected = useSelector(selectedYear);
  const handleChange = (value) => {
    dispatch(changeSelectedYear(value));
  };
  return (
    <Select
      defaultValue={yearSelected}
      className="header-custom-select"
      onChange={handleChange}
      getPopupContainer={(triggerNode) => triggerNode.parentElement}
    >
      {optionsArr.map((opt) => (
        <Option key={opt} value={opt}>
          {opt}
        </Option>
      ))}
    </Select>
  );
};

const Header = () => {
  const loading = useSelector(stateLoading);
  const data = useSelector(unitsList);
  const dispatch = useDispatch();
  const [email, setEmail] = useState();
  const [baseId, setBaseId] = useState();
  const [password, setPassword] = useState();
  const [userType, setUserType] = useState(localStorage.getItem("userType"));
  const [open, setOpen] = useState(false);
  const options =
    data?.length > 0
      ? data?.map((item) => ({ label: item.name, value: item.id }))
      : [];

  useEffect(() => {
    getTableList();
  }, []);
  const registerOwner = () => {
    console.log("Test APIII");
    const obj = {
      email: email,
      password: password,
      airTableType: "OWNER",
      apiData: {
        apiKay: localStorage.getItem("apiKey"),
        baseId: baseId,
      },
    };
    dispatch(SignUpAction(obj));
    closeDrawer();
  };
  const getTableList = () => {
    closeDrawer();
  };
  const showDrawer = () => {
    setOpen(true);
  };
  const closeDrawer = () => {
    setOpen(false);
  };
  const handleChange = (value) => {
    setBaseId(value);
  };

  const onFinish = (values) => {
    console.log("Success:", values);
    const { email, password, unit } = values;
    const obj = {
      email: email,
      password: password,
      airTableType: "OWNER",
      apiData: {
        apiKay: localStorage.getItem("apiKey"),
        baseId: unit,
      },
    };
    dispatch(SignUpAction(obj, closeDrawer));
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="main-header-container" style={{ marginTop: "48px" }}>
      {/* {loading && <PageLoader />} */}
      <div className="card-wrapper">
        <div className="main-header-content">
          <img
            src={yearly}
            alt="Yearly Overview"
            className="yearly-overview-image"
            style={{ width: "30px", height: "30px" }}
          />
          <div className="main-header main-header-heading-overview">
            Yearly Overview
          </div>
        </div>
        <UserInfo />
      </div>

      {/* {userType === "ADMIN" ? (
        <span onClick={showDrawer}>
          <CustomButton type="primary">Add Owner</CustomButton>
        </span>
      ) : null} */}
      <Drawer title="ADD OWNER" onClose={closeDrawer} open={open}>
        <Form
          name="addowner"
          className="add-owner-form"
          layout="vertical"
          initialValues={{
            remember: false,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: "Please enter email",
              },
            ]}
          >
            <Input
              style={{ height: "40px" }}
              type="text"
              className="header-custom-input"
              placeholder="bookluxury@mail.com"
            ></Input>
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Please enter password",
              },
            ]}
          >
            <Input
              style={{ height: "40px" }}
              type="text"
              className="header-custom-input"
              placeholder="*********"
            ></Input>
          </Form.Item>
          <Form.Item
            label="Select Unit"
            className="header-custom-select-container"
            name="unit"
            rules={[
              {
                required: true,
                message: "Please select unit",
              },
            ]}
          >
            <Select
              className="header-custom-select"
              options={options}
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
            />
          </Form.Item>
          <Button
            className="submit-btn header-btn"
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            Submit
          </Button>
        </Form>
      </Drawer>
    </div>
  );
};

export { DropDown };
export default Header;
